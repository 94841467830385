import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface User {
  nombreProveedor: string;
  rutProveedor: string;
  contactoEmail: string;
  password: string;
  id_token: string;
  access_token: string;
  refreshToken: string;
}

export interface UserPass {
  rutProveedor: string;
  nombre: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
  userPass: UserPass;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  userPass = {} as UserPass;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Get current user object
   * @returns UserPass
   */
  get currentUserPass(): UserPass {
    return this.userPass;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getAuthErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_AUTH_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    JwtService.saveToken(this.user.id_token);
    JwtService.saveTokenRefresh(this.user.refreshToken);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_USER_PASS](user) {
    this.userPass = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    const params = {
      params: {
        ...credentials,
      },
    };
    return ApiService.post("/OAuth/v1/usuario/signIn", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_USER, response.data);
        this.context.commit(Mutations.SET_AUTH_ERROR, response.data.errors);
        JwtService.destroyToken();
        throw new Error();
      });
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return ApiService.patch(`/usuario/v1/gcm/${payload.id}`, payload)
      .catch(({ response }) => {
        throw new Error();
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_AUTH_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](params) {
    return ApiService.post("/Usuario/v1/solicitarContrasena", params)
      .then(({ data }) => {
        console.log(data);
        //this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_AUTH_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.VERIFY_TOKEN_PASSWORD](params) {
    return ApiService.get(`/usuario/v1/${params}/resetearContrasena`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_PASS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_AUTH_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPDATE_PASSWORD](params) {
    return ApiService.put(`/Usuario/v1/${params.token}/contrasena`, params)
      .then(({ data }) => {
        console.log(data);
        //this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_AUTH_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.VERIFY_AUTH](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      //const authorize = params.meta.authorize;
      //console.log("authorize " + params.meta.authorize + "rol ");
      params = {
        tokenRefresco: JwtService.getToken(),
      };
      ApiService.post("/OAuth/v1/validarToken", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          //Serevisa si tiene los permisos para la vista
          //if (authorize) {
            //console.log(
            //  "authorize " + authorize + "rol " + JSON.stringify(data)
            //);
            //if (authorize.length && !authorize.includes(data.rol)) {
              this.context.commit(Mutations.PURGE_AUTH);
            //}
          //}
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_AUTH_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
