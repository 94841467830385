import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Reserva {
  id: number;
  clientId: string;
  driverId: string;
  codigoSegmentoVehiculo: string;
  segmentoVehiculo: string;
  marcaVehiculo: string;
  modeloVehiculo: string;
  versionVehiculo: number;
  fechaRetiro: Date;
  horaRetiro: string;
  fechaDevolucion: Date;
  horaDevolucion: string;
  estacionRetiro: string;
  estacionDevolucion: string;
  numeroReserva: string;
  neto: number | undefined;
  totalExtras: number | undefined;
  base: number | undefined;
  iva: number | undefined;
  bruto: number | undefined;
  codigoEstado: string;
  estado: string;
  reservaConfirmada: boolean;
  reservaAnulada: boolean;
  email: string;
  rut: string;
  nombre: string;
  telefono: string;
  esCompartido: boolean;
  cuposTotal: number | undefined;
  cuposDisponibles: number | undefined;
}

export interface ReservaInfo {
  errors: unknown;
  reserva: Reserva;
  reservas: Array<Reserva>;
  resultados: Array<Reserva>;
}

@Module
export default class ReservaModule extends VuexModule implements ReservaInfo {
  errors = {};
  reserva = {} as Reserva;
  reservas = [] as Array<Reserva>;
  resultados = [] as Array<Reserva>;
  reservasPorAceptar = [] as Array<Reserva>;

  /**
   * Get current reserva object
   * @returns Reserva
   */
  get reservaId(): Reserva {
    return localStorage.reserva;
  }

  /**
   * Get current reserva object
   * @returns Reserva
   */
  get currentReserva(): Reserva {
    return this.reserva;
  }

  /**
   * Get all reservas
   * @returns Array<Reserva>
   */
  get allReservas(): Array<Reserva> {
    return this.reservas;
  }
  

    /**
   * Get all reservas
   * @returns Array<Reserva>
   */
     get allReservasPorAceptar(): Array<Reserva> {
      return this.reservasPorAceptar;
    }

    /**
   * Get all reservas
   * @returns Array<Reserva>
   */
     get allResultados(): Array<Reserva> {
      return this.resultados;
    }  

  /**
   * Get errors
   * @returns array
   */
  get getReservaErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_RESERVA_ID](id) {
    localStorage.reserva = id;
  }

  @Mutation
  [Mutations.SET_RESERVA_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_RESERVA](reserva) {
    this.reserva = reserva;
  }

  @Mutation
  [Mutations.SET_RESERVAS](reservas) {
    this.reservas = reservas;
  }

  @Mutation
  [Mutations.SET_RESERVAS_POR_ACEPTAR](reservas) {
    this.reservasPorAceptar = reservas;
  }

  @Mutation
  [Mutations.SET_RESULTADOS](reservas) {
    this.resultados = reservas;
  }

  @Action
  [Actions.GET_RESERVAS](rut) {
    return ApiService.get(`rent/reserva/v1/misreservas/vigentes/${rut}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVAS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
      });
  }
 
  @Action
  [Actions.GET_RESERVAS_VENCIDAS](rut) {
    return ApiService.get(`rent/reserva/v1/misreservas/${rut}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVAS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_RESERVAS_BUSCAR](params) {
    return ApiService.post(`rent/reserva/v1/buscar/${params.rut}`, params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESULTADOS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_RESERVA_PROXIMA](id) {
    return ApiService.get(`reservaLop/v1/proxima/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_RESERVA](id) {
    return ApiService.get(`rent/reserva/v1/reserva/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_RESERVA_PAGO](id) {
    return ApiService.get(`rent/reserva/v1/token/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.INS_RESERVA](params) {
    return ApiService.post(`reservaLop/v1/reserva/`, params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_RESERVA](params) {
    return ApiService.post(
      `rent/reserva/v1/modificar/${params.reservation}`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.GET_RESERVA_NUMERO](params) {
    return ApiService.post(
      `rent/reserva/v1/obtener/${params.reservation}`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.GET_RESERVA_NUMERO_CONDUCTOR](params) {
    return ApiService.get(
      `rent/reserva/v1/consultar/reserva/${params.numeroReserva}/client/${params.clientId}/driver/${params.driverId}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }
   
  
  @Action
  [Actions.DEL_RESERVA](params) {
    return ApiService.post(
      `rent/reserva/v1/anular/${params.reservation}`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }


  @Action
  [Actions.UPD_RESERVA_VEHICULO](params) {
    return ApiService.patch(
      `rent/v1/reserva/vehiculo/${params.reservaId}`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_RESERVA_EXTRAS](params) {
    return ApiService.patch(
      `rent/reserva/v1/extras/${params.reservaId}`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_RESERVA_EXTRAS_CONTINUE](params) {
    return ApiService.patch(
      `rent/reserva/v1/extras/${params.reservaId}/continuar`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_RESERVA_PUNTOS](params) {
    ApiService.setHeader();
    return ApiService.patch(
      `rent/reserva/v1/puntos/${params.reservaId}`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_RESERVA_PERSONAL](params) {
    return ApiService.patch(
      `rent/reserva/v1/personal/${params.reservaId}`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_RESERVA_REINTENTO](reservaId) {
    return ApiService.get(
      `rent/reserva/v1/reintento/${reservaId}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_RESERVA_CONFIRMAR](reservaId) {
    return ApiService.patch(
      `reservaLop/v1/reserva/confirmar/${reservaId}`,
      reservaId
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.UPD_RESERVA_ACEPTAR](reservaId) {
    return ApiService.patch(
      `reservaLop/v1/reserva/aceptar/${reservaId}`,
      reservaId
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_ERROR, response.data.errors);
        throw new Error();
      });
  }

}
