import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Driver {
  driverId: string;
  clientId: string;
}

export interface DriverInfo {
  errors: unknown;
  driver: Driver;
}

@Module
export default class DriverModule extends VuexModule implements DriverInfo {
  errors = {};
  driver = {} as Driver;
 
  /**
   * Get current driver object
   * @returns Driver
   */
  get currentDriver(): Driver {
    return this.driver;
  }

  /**
   * Get driver errors
   * @returns array
   */
  get getDriverErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_DRIVER_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_DRIVER](driver) {
    this.driver = driver;
    this.errors = [];
  }

  @Action
  [Actions.INS_DRIVER](driverInfo) {
    ApiService.setHeader();
    return ApiService.post(`/driver/v1/registrar`, driverInfo)
      .catch(({ response }) => {
        throw new Error();
      });
  }
}
