import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Campania {
  campaniaId: string;
  nombre: string;
  imagen: string;
  sucursalOrigen: string;
  sucursalDestino: string;
  tipoVehiculo: string;
  detalle: string;
  desde: Date;
  hasta: Date;
}

export interface CampaniaInfo {
  errors: unknown;
  campania: Campania;
}

@Module
export default class CampaniaModule extends VuexModule implements CampaniaInfo {
  errors = {};
  campania = {} as Campania;
 
  /**
   * Get current campania object
   * @returns campania
   */
  get currentCampania(): Campania {
    return this.campania;
  }

  /**
   * Get errors
   * @returns array
   */
  get getCampaniaErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_CAMPANIA_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CAMPANIA](campania) {
    this.campania = campania;
  }

  
  @Action
  [Actions.GET_CAMPANIA](campaniaId) {
    return ApiService.get(`campana/v1/campana/${campaniaId}`) 
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CAMPANIA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_CAMPANIA_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_CAMPANIA_PUNTOS]() {
    return ApiService.get(`campana/v1/puntos`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CAMPANIA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_CAMPANIA_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_CAMPANIA_DISPONIBILIDAD](params) {
    return ApiService.post(
      `campana/v1/disponibilidad`,
      params
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DISPONIBILIDAD, data);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_DISPONIBILIDAD_ERROR,
          response.data.errors
        );
        throw new Error();
      });
  }

  @Action
  [Actions.GET_CAMPANIA_SUCURSALES_ORIGEN](campaniaId) {
    return ApiService.get(`campana/v1/sucursales/origen/${campaniaId}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SUCURSALES, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_SUCURSAL_ERROR, response.data.errors);
      });
  }


  @Action
  [Actions.GET_CAMPANIA_SUCURSALES_DESTINO](campaniaId) {
    return ApiService.get(`campana/v1/sucursales/destino/${campaniaId}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SUCURSALES_DESTINO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_SUCURSAL_DESTINO_ERROR, response.data.errors);
      });
  }


  
}
