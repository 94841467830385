enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  VERIFY_TOKEN_PASSWORD = "verifyTokenPassword",
  UPDATE_PASSWORD = "updatePassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  GET_RESERVAS = "getReservas",
  GET_RESERVAS_VENCIDAS = "getReservasVencidas",
  GET_RESERVAS_BUSCAR = "getReservasBuscar",
  GET_RESERVA = "getReserva",
  GET_RESERVA_PAGO = "getReservaPago",
  GET_RESERVA_PROXIMA = "getReservaProxima",
  GET_RESERVA_NUMERO = "getReservaNumero",
  GET_RESERVA_NUMERO_CONDUCTOR = "getReservaNumeroConductor",
  
  INS_RESERVA = "createReserva",
  UPD_RESERVA_VEHICULO = "updateReservaVehiculo",
  UPD_RESERVA_EXTRAS = "updateReservaExtras",
  UPD_RESERVA_EXTRAS_CONTINUE = "updateReservaExtrasContinue",  
  UPD_RESERVA_PUNTOS = "updateReservaPuntos",
  UPD_RESERVA_PERSONAL = "updateReservaPersonal",
  UPD_RESERVA_REINTENTO = "updateReservaPagoReintento",
  UPD_RESERVA_CONFIRMAR = "updateReservaConfirmar",
  UPD_RESERVA_ACEPTAR = "updateReservaAceptar",
  UPD_RESERVA = "updateReserva",
  DEL_RESERVA = "deleteReserva",

  INS_DRIVER = "createDriver",

  GET_RESERVA_PAGADOR = "getReservaPagador",
  GET_CAMPANIA = "getCampania",
  GET_CAMPANIA_PUNTOS = "getCampaniaPuntos",
  GET_CAMPANIA_DISPONIBILIDAD = "getCampaniaDisponibilidad",
  GET_CAMPANIA_SUCURSALES_ORIGEN = "getCampaniaSucuralesOrigen",
  GET_CAMPANIA_SUCURSALES_DESTINO = "getCampaniaSucuralesDestino",

  GET_EXTRAS = "getExtras",
  GET_EXTRAS_RESERVA = "getExtrasReserva",

  SET_THEME_MODE_ACTION = "setThemeModeAction",
  	
  GET_VIAJES_COMPARTIDOS = "getViajesCompartidos", 
  GET_VIAJES_SOLICITADOS = "getViajesSolicitados",
  GET_CUPOS_VIAJES_COMPARTIDOS= "getCuposViajesCompartidos",
  GET_VIAJE_COMPARTIDO = "getViajeCompartido",
  INS_VIAJE_COMPARTIDO = "createViajeCompartido",
  UPD_VIAJE_COMPARTIDO_PERSONAL = "updateViajeCompartidoPersonal",
  UPD_VIAJE_COMPARTIDO_CONFIRMAR = "updateViajeCompartidoConfirmar",
  UPD_VIAJE_COMPARTIDO = "updateViajeCompartido",
  UPD_VIAJE_ACEPTAR = "updateViajeAceptar",
  UPD_VIAJE_RECHAZAR = "updateViajeRechazar", 
  DEL_VIAJE_COMPARTIDO = "deleteViajeCompartido",



  GET_TRAZABILIDAD_INICIO = "getTrazabilidadInicio",
  GET_TRAZABILIDAD_TERMINO = "getTrazabilidadTermino",
  GET_TRAZABILIDAD = "getTrazabilidad",
  INS_TRAZABILIDAD = "createTrazabilidad",
  UPD_TRAZABILIDAD = "updateTrazabilidad",
  DEL_TRAZABILIDAD = "deleteTrazabilidad",

  GET_NOTIFICACIONES = "getNotificaciones",
  UPD_NOTIFICACION = "updateNotificacion",
  UPD_NOTIFICACION_ARCHIVAR = "updateNotificacionArchivar",

  GET_SUCURSALES = "getSucursales",
  GET_SUCURSALES_DESTINO = "getSucursalesDestino",
  GET_SUCURSAL = "getSucursal",

  GET_DISPONIBILIDAD = "getDisponibilidad",
  GET_SEGMENTO = "getSegmento",

  GET_BUSQUEDAS = "getBusquedas",
  DEL_BUSQUEDA = "deleteBUsqueda",

}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  SET_RESERVA = "setReserva",
  SET_RESERVA_ID = "setReservaId",
  SET_RESERVAS = "setReservas",
  SET_RESERVAS_POR_ACEPTAR = "setReservasPorAceptar",
  SET_RESULTADOS = "setResultados",
  SET_SUCURSAL = "setSucursal",
  SET_SUCURSALES = "setSucursales",
  SET_SUCURSALES_DESTINO = "setSucursalesDestino",

  SET_RESERVA_PAGADOR = "setReservaPagador",
  SET_CAMPANIA = "setCampania",
  SET_EXTRA = "setExtra",
  SET_EXTRAS = "setExtras",
  SET_EXTRAS_RESERVA = "setExtrasReserva",

  SET_DRIVER = "setDriver",

  SET_TRAZABILIDAD = "setTrazabilidad",
  SET_TRAZABILIDADES = "setTrazabilidades",

  SET_NOTIFICACION = "setNotificacion",
  SET_NOTIFICACIONES = "setNotificaciones",

  SET_VIAJE_COMPARTIDO = "setViajeCompartido",
  SET_VIAJES_COMPARTIDOS = "setViajesCompartidos",

  SET_DISPONIBILIDAD = "setDisponibilidad",
  SET_SEGMENTO = "setSegmento",

  SET_BUSQUEDA = "setBusqueda",
  SET_BUSQUEDAS = "setBusquedas",

  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_USER_PASS = "setUserPass",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_AUTH_ERROR = "setError",
  SET_RESERVA_ERROR = "setReservaError",
  SET_RESERVA_PAGADOR_ERROR = "setReservaPagadorError",
  SET_CAMPANIA_ERROR = "setCampaniaError",
  SET_DRIVER_ERROR = "setDriverError",
  SET_EXTRA_ERROR = "setExtraError",
  SET_NOTIFICACION_ERROR = "setNotificacionError",
  SET_DISPONIBILIDAD_ERROR = "setDisponibilidadError",
  SET_SUCURSAL_ERROR = "setSucursalError",
  SET_SUCURSAL_DESTINO_ERROR = "setSucursalDestinoError",
  SET_BUSQUEDA_ERROR = "setBusquedaError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
}

export { Actions, Mutations };
