import { User } from 'oidc-client'
import { createOidcAuth, SignInType, LogLevel } from './vue-oidc-client'
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import store from '../store';
const loco = window.location
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`

const idsrvAuth = createOidcAuth(
  'main',
  SignInType.Window,
  appRootUrl,
  {
    authority: "https://uat-sso.europcar.cl/",//"https://localhost:44395/",process.env.VUE_APP_SSO_URL,//
    client_id: "gestor", // 'implicit.shortlived',
    response_type: "code",
    scope: "openid profile email phone roles",
    client_secret: process.env.VUE_APP_SSO_SECRET,//"901564A5-E7FE-42CB-B10D-61EF6A8F3654",
    // test use
    prompt: "login",
  },
  console,
  LogLevel.Debug
)

// handle events
idsrvAuth.events.addAccessTokenExpiring(function() {
  // eslint-disable-next-line no-console
  console.log('access token expiring')
})

idsrvAuth.events.addAccessTokenExpired(function() {
  // eslint-disable-next-line no-console
  console.log('access token expired')
})

idsrvAuth.events.addSilentRenewError(function(err: Error) {
  // eslint-disable-next-line no-console
  console.error('silent renew error', err)
})

idsrvAuth.events.addUserLoaded(function(user: User) {
  // eslint-disable-next-line no-console
  console.log('user addUserLoaded', JSON.stringify(user));
  console.log(JSON.stringify(store));
  store.commit(
    Mutations.SET_AUTH,
    user
  );
  store.dispatch(Actions.INS_DRIVER, user.profile);
})

idsrvAuth.events.addUserUnloaded(function() {
  // eslint-disable-next-line no-console
  console.log('user unloaded')
})

idsrvAuth.events.addUserSignedOut(function() {
  // eslint-disable-next-line no-console
  console.log('user signed out')
})

idsrvAuth.events.addUserSessionChanged(function() {
  // eslint-disable-next-line no-console
  console.log('user session changed')
})

export default idsrvAuth
