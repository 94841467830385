import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface ReservaPagador {
  reservaId: string;
  clientId: string;
  driverId: string;
  customerCode: string;
  numeroIdentificacion: string;
  nombres: string;
  apellidosPaterno: string;
  apellidosMaterno: number;
  telefono: string;
  email: string;
  direccion: string;
  region: string;
  comuna: string;
  pais: string;
  numeroVuelo: string;
  numeroLicencia: string;
}

export interface ReservaPagadorInfo {
  errors: unknown;
  pagador: ReservaPagador;
  pagadores: Array<ReservaPagador>;
}

@Module
export default class ReservaPagadorModule extends VuexModule implements ReservaPagadorInfo {
  errors = {};
  pagador = {} as ReservaPagador;
  pagadores = [] as Array<ReservaPagador>;
 
  /**
   * Get current reservaPagador object
   * @returns ReservaPagador
   */
  get currentReservaPagador(): ReservaPagador {
    return this.pagador;
  }

  /**
   * Get errors
   * @returns array
   */
  get getReservaPagadorErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_RESERVA_PAGADOR_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_RESERVA_PAGADOR](pagador) {
    this.pagador = pagador;
  }

  
  @Action
  [Actions.GET_RESERVA_PAGADOR](reservaId) {
    return ApiService.get(`rent/reserva/v1/personal/${reservaId}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESERVA_PAGADOR, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_RESERVA_PAGADOR_ERROR, response.data.errors);
      });
  }

  
}
