import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Notificacion {
  id: number;
  reservaId: string;
  esInicio: boolean;
  kilometrosActuales: string;
  imagenKilometros: string;
  nivelCombustible: string;
  imagenCombustible: string;
  limpieza: string;
  imagenLimpieza: string;
  cargaCombustible: boolean| undefined;
  cargaDinero: number| undefined;
}

export interface NotificacionInfo {
  errors: unknown;
  notificacion: Notificacion;
  notificaciones: Array<Notificacion>;
}

@Module
export default class NotificacionModule extends VuexModule implements NotificacionInfo {
  errors = {};
  notificacion = {} as Notificacion;
  notificaciones = [] as Array<Notificacion>;

  /**
   * Get current notificacion object
   * @returns Notificacion
   */
  get currentNotificacion(): Notificacion {
    return this.notificacion;
  }

  /**
   * Get all notificaciones
   * @returns Array<Notificacion>
   */
  get allNotificaciones(): Array<Notificacion> {
    return this.notificaciones;
  }

  /**
   * Get errors
   * @returns array
   */
  get getNotificacionErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_NOTIFICACION_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_NOTIFICACION](notificacion) {
    this.notificacion = notificacion;
  }

  @Mutation
  [Mutations.SET_NOTIFICACIONES](notificaciones) {
    this.notificaciones = notificaciones;
  }


  @Action
  [Actions.GET_NOTIFICACIONES]() {
    return ApiService.get(`Notificacion/v1/notificacions`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_NOTIFICACIONES, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_NOTIFICACION_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPD_NOTIFICACION](id) {
    return ApiService.put(`Notificacion/v1/leida/${id}`, id)
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_NOTIFICACION_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPD_NOTIFICACION_ARCHIVAR](id) {
    return ApiService.put(`Notificacion/v1/archivar/${id}`, id)
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_NOTIFICACION_ERROR, response.data.errors);
      });
  }

}
