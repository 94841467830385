import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Extra {
  extraId: number;
  extra: string;
  descripcion: string;
  value: number;
  taxRate: number;
  insurance: boolean;
  excess: number;
  extraByDay: boolean;
}

export interface ExtraInfo {
  errors: unknown;
  extra: Extra;
  extras: Array<Extra>;
  extrasReserva: Array<Extra>;
}

@Module
export default class ExtraModule extends VuexModule implements ExtraInfo {
  errors = {};
  extra = {} as Extra;
  extras = [] as Array<Extra>;
  extrasReserva = [] as Array<Extra>;

  /**
   * Get current extra object
   * @returns Extra
   */
  get currentExtra(): Extra {
    return this.extra;
  }

  /**
   * Get all extras
   * @returns Array<Extra>
   */
  get allExtras(): Array<Extra> {
    return this.extras;
  }

  /**
   * Get all extras
   * @returns Array<Extra>
   */
   get allExtrasReserva(): Array<Extra> {
    return this.extrasReserva;
  }


  /**
   * Get errors
   * @returns array
   */
  get getExtraErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_EXTRA_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_EXTRA](extra) {
    this.extra = extra;
  }

  @Mutation
  [Mutations.SET_EXTRAS](extras) {
    this.extras = extras;
  }

  @Mutation
  [Mutations.SET_EXTRAS_RESERVA](extras) {
    this.extrasReserva = extras;
  }

  @Action
  [Actions.GET_EXTRAS](id) {
    return ApiService.get(`rent/reserva/v1/extras/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EXTRAS, data.extras);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_EXTRA_ERROR, response.data.errors);
        throw new Error();
      });
  }

  @Action
  [Actions.GET_EXTRAS_RESERVA](id) {
    return ApiService.get(`rent/reserva/v1/extras/contratados/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EXTRAS_RESERVA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_EXTRA_ERROR, response.data.errors);
        throw new Error();
      });
  }
}
