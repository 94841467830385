import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Disponibilidad {
  reservaId: string;
  segmentos: Array<any>;
}

export interface Segmento {
  codigoSegmentoVehiculo: string;
  SegmentoVehiculo: string;
}

export interface DisponibilidadInfo {
  errors: unknown;
  disponibilidad: Disponibilidad;
  segmento:Segmento;
}

@Module
export default class DisponibilidadModule
  extends VuexModule
  implements DisponibilidadInfo
{
  errors = {};
  disponibilidad = {} as Disponibilidad;
  segmento = {} as Segmento;

  /**
   * Current disponibilidad
   * @returns Disponibilidad
   */
  get currentDisponibilidad(): Disponibilidad {
    return this.disponibilidad;
  }

    /**
   * Current segmento
   * @returns Segmento
   */
     get currentSegmento(): Segmento {
      return this.segmento;
    }

  /**
   * Get errors
   * @returns array
   */
  get getDisponibilidadErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_DISPONIBILIDAD_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_DISPONIBILIDAD](disponibilidad) {
    this.disponibilidad = disponibilidad;
  }

  @Mutation
  [Mutations.SET_SEGMENTO](segmento) {
    this.segmento = segmento;
  }

  @Action
  [Actions.GET_DISPONIBILIDAD](params) {
    return ApiService.post(
      `rent/Disponibilidad/v1/disponibilidad`,
      params 
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DISPONIBILIDAD, data);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_DISPONIBILIDAD_ERROR,
          response.data.errors
        );
        throw new Error();
      });
  }
}
