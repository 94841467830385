import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Busqueda {
  busqueda: string;
}

export interface BusquedaInfo {
  errors: unknown;
  busqueda: Busqueda;
  busquedas: Array<Busqueda>;
}

@Module
export default class BusquedaModule extends VuexModule implements BusquedaInfo {
  errors = {};
  busqueda = {} as Busqueda;
  busquedas = [] as Array<Busqueda>;

  /**
   * Get current busqueda object
   * @returns busqueda
   */
  get currentBusqueda(): Busqueda {
    return this.busqueda;
  }

    /**
   * Get all busquedas
   * @returns Array<Busqueda>
   */
     get allBusquedas(): Array<Busqueda> {
      return this.busquedas;
    }

  /**
   * Get errors
   * @returns array
   */
  get getBusquedaErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_BUSQUEDA_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_BUSQUEDA](busqueda) {
    this.busqueda = busqueda;
  }

  @Mutation
  [Mutations.SET_BUSQUEDAS](busquedas) {
    this.busquedas = busquedas;
  }
  
  @Action
  [Actions.GET_BUSQUEDAS]() {
    ApiService.setHeader();
    return ApiService.get(`busqueda/v1/busquedas`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BUSQUEDAS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_BUSQUEDA_ERROR, response.data.errors);
      });
  }

  

  @Action
  [Actions.DEL_BUSQUEDA](buscar) {
    return ApiService.get(`busqueda/v1/busqueda/${buscar}`)
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_BUSQUEDA_ERROR, response.data.errors);
      });
  }


  
}
