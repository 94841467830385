import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import SucursalModule from "@/store/modules/SucursalModule";
import ReservaModule from "@/store/modules/ReservaModule";
import ReservaPagadorModule from "@/store/modules/ReservaPagadorModule";
import DisponibilidadModule from "@/store/modules/DisponibilidadModule";
import NotificacionModule from "@/store/modules/NotificacionModule";
import ExtraModule from "@/store/modules/ExtraModule";
import AuthModule from "@/store/modules/AuthModule";
import CampaniaModule from "@/store/modules/CampaniaModule";
import BusquedaModule from "@/store/modules/BusquedaModule";
import DriverModule from "@/store/modules/DriverModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";

config.rawError = true;

const store = createStore({
  modules: {
    SucursalModule,
    ReservaModule,
    ReservaPagadorModule,
    DisponibilidadModule,
    NotificacionModule,
    ExtraModule,
    AuthModule,
    CampaniaModule,
    DriverModule,
    BodyModule,
    BusquedaModule,
    ConfigModule,
    ThemeModeModule,
  },
});

export default store;
