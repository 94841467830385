import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import idsrvAuth from "../config/idsrvAuth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth"      
    },

    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
          authName: idsrvAuth.authName        
        },
      }, 
      {
        path: "/agenda",
        name: "agenda",
        component: () => import("@/views/agenda/pages/Agenda.vue"),
        meta: {
          pageTitle: "Agenda",
          breadcrumbs: ["Agenda"],
          authName: idsrvAuth.authName        
        },
      },
      {
        path: "/accidents",
        name: "accidents",
        component: () => import("@/views/accidents/pages/Declaration.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
          authName: idsrvAuth.authName        
        },
      },
      {
        path: "/assists",
        name: "assists",
        component: () => import("@/views/assists/pages/Assists.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
          authName: idsrvAuth.authName        
        },
      },
      {
        path: "/reservations/pass",
        name: "reservation-pass",
        component: () => import("@/views/reservations/Pass.vue"),
        meta: {
          pageTitle: "Historial",
          breadcrumbs: ["Reservas"],
          authName: idsrvAuth.authName
        },
      },
      {
        path: "/replacement",
        name: "replacement",
        component: () => import("@/views/SearchReplacement.vue"),
        meta: {
          pageTitle: "Reservas",
          breadcrumbs: ["Reemplazos"],
          authName: idsrvAuth.authName,
          roles: ["Replacement"]
        },
      },
      {
        path: "/search",
        name: "search",
        component: () => import("@/views/Search.vue"),
        meta: {
          pageTitle: "Reservas",
          breadcrumbs: ["Nueva Reserva"],
          authName: idsrvAuth.authName
        },
      },
      {
        path: "/results",
        name: "results",
        component: () => import("@/views/Results.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
          authName: idsrvAuth.authName
        },
      },
      {
        path: "/extras",
        name: "extras",
        component: () => import("@/views/Extras.vue"),
        meta: {
          pageTitle: "Extras",
          breadcrumbs: ["Extras"],
          authName: idsrvAuth.authName
        },
      },
      {
        path: "/personal",
        name: "personal",
        component: () => import("@/views/Personal.vue"),
        meta: {
          pageTitle: "Personal",
          breadcrumbs: ["Personal"],
          authName: idsrvAuth.authName      
        },
      },
      {
        path: "/voucher/:token",
        name: "voucher",
        component: () => import("@/views/Voucher.vue"),
        meta: {
          pageTitle: "Personal",
          breadcrumbs: ["Personal"],
          authName: idsrvAuth.authName
        },
      },
      {
        path: "/reservations/details/:id",
        name: "details",
        component: () => import("@/views/reservations/Details.vue"),
        meta: {
          pageTitle: "Detalle Reserva",
          breadcrumbs: ["Detalle Reserva"],
          authName: idsrvAuth.authName
        },
      },
      {
        path: "/reservations/infox/:id/driver/:driver/client/:client",
        name: "info",
        component: () => import("@/views/reservations/Reservation.vue"),
        meta: {
          pageTitle: "Detalle Reserva",
          breadcrumbs: ["Detalle Reserva"],
          //authName: idsrvAuth.authName
        },
      },
      
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/LayoutBuilder.vue"),
        meta: {
          pageTitle: "Layout Builder",
          breadcrumbs: ["Layout"],
        },
      },
      {
        path: "/campanias/campania/:id",
        name: "campania",
        component: () => import("@/views/campanias/Campania.vue"),
        meta: {
          pageTitle: "Personal",
          breadcrumbs: ["Personal"],
        },
      },
      
    ],
  },
  {
    path: "/reservation",
    //component: () => import("@/components/page-layouts/InfoReservation.vue"),
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "info/:id/driver/:driver/client/:client",
        name: "info-reservation",
        component: () => import("@/views/reservations/Reservation.vue"),
        meta: {
          pageTitle: "Detalle Reserva",
          breadcrumbs: ["Detalle Reserva"],
        },
      },   
    ]
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/welcome",
        name: "welcome-user",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Welcome.vue"),
        meta: {
          pageTitle: "Welcome",
        },
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Welcome.vue"),
        meta: {
          pageTitle: "Gestor de Reservas"
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    //component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/403",
        name: "403",
        component: () => import("@/views/crafted/authentication/Error403.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
 /* {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },*/
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  //store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // before page access check if page requires authentication
  if (typeof(to.meta.roles)!='undefined') {
    const role = to.meta.roles as string;
    if (Array.from(role).includes(idsrvAuth?.userProfile.role)) {
      next();
    } else {
      next({ name: "403" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});
idsrvAuth.useRouter(router);
export default router;
