import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Sucursal {
  SucursalId: string;
  nombre: string;
}

export interface SucursalInfo {
  errors: unknown;
  errorsDestino: unknown;
  sucursal: Sucursal;
  sucursales: Array<Sucursal>;
  sucursalesDestino: Array<Sucursal>;
}

@Module
export default class SucursalModule extends VuexModule implements SucursalInfo {
  errors = {};
  errorsDestino = {};
  sucursal = {} as Sucursal;
  sucursales = [] as Array<Sucursal>;
  sucursalesDestino = [] as Array<Sucursal>;

  /**
   * Get current sucursal object
   * @returns Sucursal
   */
  get currentSucursal(): Sucursal {
    return this.sucursal;
  }

  /**
   * Get all sucursales
   * @returns Array<Sucursal>
   */
  get allSucursales(): Array<Sucursal> {
    return this.sucursales;
  }

  /**
   * Get all sucursales
   * @returns Array<Sucursal>
   */
   get allSucursalesDestino(): Array<Sucursal> {
    return this.sucursalesDestino;
  }

  /**
   * Get errors
   * @returns array
   */
  get getSucursalErrors() {
    return this.errors;
  }

    /**
   * Get errors
   * @returns array
   */
     get getSucursalDestinoErrors() {
      return this.errorsDestino;
    }

  @Mutation
  [Mutations.SET_SUCURSAL_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUCURSAL_DESTINO_ERROR](error) {
    this.errorsDestino = error;
  }

  @Mutation
  [Mutations.SET_SUCURSAL](sucursal) {
    this.sucursal = sucursal;
  }

  @Mutation
  [Mutations.SET_SUCURSALES](sucursales) {
    this.sucursales = sucursales;
  }
  @Mutation
  [Mutations.SET_SUCURSALES_DESTINO](sucursales) {
    this.sucursalesDestino = sucursales;
  }

  @Action
  [Actions.GET_SUCURSALES]() {
    return ApiService.get(`rent/sucursal/v1/sucursales`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SUCURSALES, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_SUCURSAL_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_SUCURSAL](codigoSucursal) {
    return ApiService.get(`rent/sucursal/v1/sucursal/${codigoSucursal}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SUCURSAL  , data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_SUCURSAL_ERROR, response.data.errors);
      });
  }
}
